import React, { Component } from "react";
import { Parallax } from "react-parallax";
// import Image1 from "https://jmilli.s3.us-east-2.amazonaws.com/ledgeLeaves.JPG";
import styled, { css } from "styled-components";
import media from "styled-media-query";
// import { LandingTitle } from "./styled/index";

const Image1 = "https://jmilli.s3.us-east-2.amazonaws.com/texture2.jpg";

const Container = styled.div`
  grid-area: 3 / 1 / 4 / -1;
  text-align: center;
  /* height: 18em; */
`;

class ParallaxDivide extends Component {
  render() {
    return (
      <Container>
        <Parallax bgImage={Image1} strength={500}>
          <div style={{ height: 150 }} />
        </Parallax>
      </Container>
    );
  }
}

export default ParallaxDivide;
