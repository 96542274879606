import React from "react";
import styled, { css } from "styled-components";
import media from "styled-media-query";

const AboutContainer = styled.div`
/* border: dashed 1px yellow; */
grid-area: 2 / 1 / 3 / -1;
  text-align: center;  
  background-color: black;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  /* padding-top: 50px; */
  padding-bottom: 70px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

export const LandingTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 40px;
  color: #fff;
  text-shadow: 4px 4px #000;
  margin-top: 60px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 30px;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

const AboutText = styled.p`
  width: 700px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-align: center;
  color: white;
  font-size: 22px;
  align-self: center;
  margin-top: 20px;
  line-height: 30px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 80%;
    font-size: 18px;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

export default function About() {
  return (
    <AboutContainer>
        <LandingTitle>Who We Are</LandingTitle>
        <AboutText>
          Ringo Stoneworks is independently owned and operated by Jonathan
          Miller out of Nelson BC.  With over 10 years in the trade his
          experience ranges from small installations and renovations to large
          custom builds on luxury homes and commercial projects.
          Our philosophy is to bring out the true potential of any home in an
          artistic and natural way, delivering a unique, custom approach to each
          and every job.  We view each opportunity as a challenge, to be met
          with artistry and experience; with the overall mission of creating a
          work of lasting beauty.
        </AboutText>
        <LandingTitle>What We Do</LandingTitle>
        <AboutText>
          We craft beautiful, high quality stoneworks for Kootenay area homes,
          including, Veneers, stone patios, paving, fireplaces, fire pits, and
          retaining walls. We work with a variety of materials, including stone,
          brick and pavers and tile.
        </AboutText>
        <LandingTitle>Where We Work</LandingTitle>
        <AboutText>
          Proudly serving Nelson, Castlegar, Trail, Rossland and all the
          surrounding Kootenay areas.
        </AboutText>
    </AboutContainer>
  );
}
