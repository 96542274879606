import React, { Component } from "react";
import styled from "styled-components";
import LandingPage from "./Landing";
import About from "./About";
import ParallaxDivide from "./ParallaxDivide";
import ImageGrid from "./Gallery";
import GalleryHeader from "./GalleryHeader";
import Contact from "./Contact";
import Footer from "./Footer";

const Grid = styled.div`
  display: grid;
  height: 100vh;
  grid-template: 100vh auto 150px 300px auto auto auto/ 1fr;
`;

export default class GridLayout extends Component {
  render() {
    return (
      <Grid>
        <LandingPage />
        <About />
        <ParallaxDivide />
        <ImageGrid />
        <GalleryHeader />
        <Contact />
        <Footer />
      </Grid>
    );
  }
}
