import React from "react";
import styled, { css } from "styled-components";
import media from "styled-media-query";

const AboutContainer = styled.div`
  grid-area: 7 / 1 / 8 / -1;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const AboutTitleContainer = styled.div`
  grid-area: 1 / 1 / 2 / -1;
  /* border: dashed 1px green; */
`;

export const LandingTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  /* margin: 0 1em; */
  padding-bottom: 15px;
  font-size: 2.2em;
  color: #fff;
  text-shadow: 4px 4px #000;
  text-align: center;
`;

const AboutTextContainer = styled.div`
  grid-area: 2 / 1 / 3 / -1;
  /* border: dashed 1px red; */
`;

const AboutText = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: white;
  padding-bottom: 10px;
`;

export default function Footer() {
  return (
    <AboutContainer>
      <AboutTextContainer>
        <LandingTitle>Jonny Ringo</LandingTitle>
        <AboutText>jonny@ringostoneworks.com</AboutText>
        <AboutText>236-601-5009</AboutText>
        <AboutText>Nelson, BC</AboutText>
      </AboutTextContainer>
    </AboutContainer>
  );
}
