import React from "react";
import styled, { css } from "styled-components";
import media from "styled-media-query";

const GalleryHeaderContainer = styled.div`
  grid-area: 4 / 1 / 5 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  /* border: dashed 1px yellow; */
`;

export const LandingTitle = styled.h1`
  /* text-align: center; */
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 4em;
  color: #fff;
  text-shadow: 4px 4px #000;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 50px;
    align-self: center;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
    font-size: 60px;
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

export default function GalleryHeader() {
  return (
    <GalleryHeaderContainer>
      <LandingTitle id="portfolio">Portfolio</LandingTitle>
    </GalleryHeaderContainer>
  );
}
