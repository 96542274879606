import React, { Component } from "react";
import styled from "styled-components";
import Gallery from "react-photo-gallery";

const GalleryContainer = styled.div`
  grid-area: 5 / 1 / 6 / -1;
  background-color: black;
`;

const photos = [
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic9.jpg",
    width: 1.5,
    height: 1
  },
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic10.jpg",
    width: 1.5,
    height: 1
  },
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic11.jpg",
    width: 1.5,
    height: 1
  },
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic14.jpg",
    width: 1.2,
    height: 1
  },

  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic15.jpg",
    width: 3,
    height: 2
  },

  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic16.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic17.jpg",
    width: 3,
    height: 2
  },

  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic20.jpg",
    width: 1,
    height: 1
  },
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic21.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic22.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://jmilli.s3.us-east-2.amazonaws.com/Pic23.jpg",
    width: 1.1,
    height: 1
  }
];

export default class ImageGrid extends Component {
  //   click = e => {
  //     console.log("I got clicked", e.target.alt);
  //     const varb = e.target.alt;
  //     // console.log("varb", varb);j
  //     window.location.href = varb;
  //   };

  render() {
    return (
      <GalleryContainer id="Projects">
        <Gallery photos={photos} margin={10} />;
      </GalleryContainer>
    );
  }
}
