import React, { Component } from "react";
import styled, { css } from "styled-components";
import media from "styled-media-query";
import { Link } from 'react-scroll'
// import { LandingTitle, Stonework } from "./styled/index";

const LandingContainer = styled.div`
  /* border: dashed 1px green; */
  grid-area: 1 / 1 / 2 / -1;
  display: flex;  
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    justify-content: center;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

const TitleContainer = styled.div`
  /* border: dashed 1px red; */
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    justify-content: center;
    margin-left: 0px;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

const LandingTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 100px;
  color: #fff;
  text-shadow: 4px 4px 6px #000;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 40px;
    align-self: center;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
    font-size: 60px;
    align-self: center;
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

export const Stonework = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 45px;
  color: #fff;
  text-shadow: 0px 0px 6px #000;
  padding-top: 10px;
  padding-left: 40px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 25px;
    padding-left: 0px;
    align-self: center;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
    font-size: 30px;
    padding-left: 0px;
    align-self: center;
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-top: 10px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    margin-left: 0px;
    flex-direction: column;
    align-self: center;
  `}

  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
    margin-left: 0px;
    flex-direction: column;
    align-self: center;
  `}

  ${media.greaterThan("large")`
    /* screen width is greater than 1170px (large) */
  `}
`;

const Button = styled.button`
    /* border: 1px blue dashed; */
    width: 230px;
    height: 65px;
    margin-top: 15px;
    /* margin-left: 40px; */
    background-color: #000;
    border: 3px solid white;
    box-shadow: 0px 0px 5px white;
    box-sizing: border-box;
    border-radius: 36px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: white;
    /* align-self: center; */
    cursor: pointer;
    transition-duration: 0.5s;
        &:hover{
        background-color: white;
        color: #000;
        opacity: 0.9;
        transform: scale(1.05);
        }
    ${media.lessThan("medium")`
        margin-right: 0px;
        margin-top: 10px;
        /* width: 200px; */
        /* height: 55px; */
    `}
    ${media.between("medium", "large")`
      align-self: center;
      margin-right: 10px;
    `}
    ${media.greaterThan("large")`
        margin-right: 10px;
    `}
`;
const BackgroundImage = styled.div`
  background-image: url("https://jmilli.s3.us-east-2.amazonaws.com/texture2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    /* background: rgba(255,255,255,0.3); */
    position: absolute;
  }
}
`;
export default class LandingPage extends Component {
  render() {
    return (
        <LandingContainer>
          <BackgroundImage />
          <TitleContainer>
            <LandingTitle>Ringo StoneWorks</LandingTitle>
            <Stonework>masonry and stonework</Stonework>
            <ButtonContainer>
              <Link activeClass="active" to="portfolio" spy={true} smooth={true} duration={2000}>
                <Button>
                  PORTFOLIO
                </Button>
              </Link>
              <Link activeClass="active" to="contact" spy={true} smooth={true} duration={2000}>
                <Button>
                  CONTACT
                </Button>
              </Link>
            </ButtonContainer>
          </TitleContainer>
        </LandingContainer>
    );
  }
}
